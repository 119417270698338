export const HUB_FEATURES = [
    {
        name: 'Performance data management',
        label: 'perfromance-data-management.svg',
        comingSoon: false
    },
    {
        name: 'Arc Essentials',
        label: 'arc-essentials.svg',
        comingSoon: true
    },
    {
        name: 'Performance monitoring',
        label: 'performance-monitoring.svg',
        comingSoon: false
    },
    {
        name: 'Advanced scoring',
        label: 'advanced-scoring.svg',
        comingSoon: true
    },
    {
        name: 'LEED v4.1 O+M Recertification',
        label: 'leedv4.1.svg',
        comingSoon: false
    },
    {
        name: 'LEED for Cities',
        label: 'leed-for-cities.svg',
        comingSoon: true
    },
    {
        name: 'LEED v4.1 O+M Readiness',
        label: 'leedv4.1.svg',
        comingSoon: false
    },
    {
        name: 'LEED for Communities',
        label: 'leed-for-communities.svg',
        comingSoon: true
    },
    {
        name: 'Performance certificates',
        label: 'performance-certificates.svg',
        comingSoon: false
    },
    {
        name: 'LEED Transit',
        label: 'leed-transit.svg',
        comingSoon: true
    },
    {
        name: 'Climate Risk',
        label: 'climate-risk.svg',
        comingSoon: false
    },
    {
        name: 'Parksmart',
        label: 'parksmart.svg',
        comingSoon: true
    },
    {
        name: 'Score modeling',
        label: 'score-modeling.svg',
        comingSoon: false
    },
    {
        name: 'EU Taxonomy',
        label: 'eu-taxonomy.svg',
        comingSoon: true
    },
    {
        name: 'Reports',
        label: 'reports.svg',
        comingSoon: false
    },
    {
        name: 'Efficiency Analysis',
        label: 'efficiency-analysis.svg',
        comingSoon: true
    }
];